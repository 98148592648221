import { useEffect, useState } from "react";

export const useTheme = () => {

  const [isDarkMode, toggleDarkMode] =  useState(true);

  const mutateClasses = (isDark: boolean) => {
    if (isDark) {
      localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
      return;
    }
    localStorage.setItem('theme', 'light')
    document.documentElement.classList.remove('dark');
  }

  useEffect(() => {
    mutateClasses(isDarkMode);
  }, [isDarkMode])

  const handleToggle = (isDark: boolean) => {
    toggleDarkMode(isDark);
    mutateClasses(isDark);
  }

  return { handleToggle, isDarkMode }
}