export const About = () => {
  return (
    <div className="mx-auto">
      <div className='w-full'>
        <h2 className="text-black dark:text-white text-lg">About</h2>
      </div>
      <div className="w-full">
        <p className="dark:text-grey text-sm">
        Hello, I'm Galo, a full stack developer. I have a passion for writing code, reading others' code, and building products from scratch. My focus is on continuous improvement, both as an engineer and as a person. I believe in steady progress, always learning, always making a difference, and always delivering results. Always growing, always contributing, always shipping.
        </p>
      </div>
    </div>
  )
}