export const Experience = () => {

  const data = [
    {
      period: '2024-Present',
      title: 'Full Stack Engineer III',
      description: 'Develop and own frontend products and components across GreenShield',
      tech: 'React.js • Go lang • Docker ',
      link: 'https://www.greenshield.ca/en-ca'
    },
    {
      period: '2021-2024',
      title: 'Senior Frontend Tech Leader',
      description: 'Part of the architecture team to build eCommerce solutions for automotive leaders like Toyota, Jaguar, Land Rover etc and tech lead for global front end developers',
      tech: 'React.js • PHP Symfony • Docker ',
      link: 'https://www.linkedin.com/company/inchcape-digital'
    }, 
    {
      period: '2020-present',
      title: 'Freelance Consultant',
      description: 'I am a consultant for startup companies that accelerates their business using technology like Point of Sale system, Booking systems and static websites',
      tech: 'React.js • React Native • Docker • Node.js • Vue.js • CI/CD pipelines ',
      link: '#'
    },
    {
      period: '2018-2021',
      title: 'Senior Full Stack Developer / Tech Lead - The Penbrothers International',
      description: 'Lead engineer for delivery team consisting of 5 developers, UI/UX designer, Business analyst and a QA engineer',
      tech: 'React.js • Node.js • PHP Laravel • Progressive web apps •  GCP',
      link: 'https://penbrothers.com/'
    },
    {
      period: '2018',
      title: 'Engineer / Designer - Nokia TC (PH)',
      description: 'Junior C++ software engineer for 4G technology',
      tech: 'C++ • Object Oriented Programming',
      link: 'https://www.nokia.com/'
    }
  ]

  return (
    <div className="mx-auto">
      <div className='w-full'>
        <h2 className="text-black dark:text-white text-lg">Work Experience</h2>
      </div>
      <div className="">
        { data.map(d => (
          <div key={d.period} className="flex">
            <div className="w-5/12">
              <h3 className="text-black dark:text-white text-sm">
                {d.period}
              </h3>
            </div>
            <div className="w-7/12 mb-5">
              <h3 className="text-blue dark:text-blue text-sm"><a href={d.link} target="__blank">{d.title}</a></h3>
              <p className="text-black dark:text-grey text-sm mb-3">{d.description}</p>
              <p className="text-black dark:text-grey text-sm">{d.tech}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}