export const Contact = () => {
  return (
    <div className="mx-auto">
      <div className='w-full'>
        <h2 className="text-black dark:text-white text-lg">Let's talk</h2>
      </div>
      <div className="w-full">
        <p className="dark:text-grey text-sm">
          While I'm not actively seeking new opportunities at the moment, my inbox is perpetually accessible. Whether you have a question or simply want to reach out and say hello @&nbsp;
          <a
            className="text-blue"
            href="mailto:johndoe@fakeemail.com, janedoe@fakeemail.com?cc=jackdoe@fakeemail.com &bcc=jennydoe@fakeemail.com &subject=this is how to use the mailto link &body=this is an article on how to use the mailto link">
              gbgarlejo@gmail.com
          </a>
          , I'll do my utmost to respond to you promptly.
        </p>
      </div>
    </div>
  )
}