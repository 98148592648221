import logo from '../../assets/images/LogoGrouped.png';
import logoDark from '../../assets/images/LogoDark.png';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useNavigate } from 'react-router-dom';

type HeaderTypes = {
  isDarkMode: boolean;
  toggleDarkMode: (isDark: boolean) => void;
}

export const Header: React.FunctionComponent<HeaderTypes> = ({ isDarkMode, toggleDarkMode }) => {

  const navigate = useNavigate();

  return (
    <div className="px-1.5 grid grid-cols-2" onClick={() => navigate("/")}>
      <div>
        <img src={isDarkMode ? logo : logoDark} alt="Galo logo" />
      </div>
      <div className="float-right fixed right-3 top-3">
        <DarkModeSwitch
          style={{ marginBottom: '2rem' }}
          checked={isDarkMode}
          onChange={toggleDarkMode}
          size={20}
        />
      </div>
    </div>
  )
}