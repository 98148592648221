import galo from '../../assets/images/about/galo.png';

export const Name = () => {
  return (
    <div className="flex mx-auto">
      <div className='sm:w-1/2 w-3/12 p-2'>
        <img src={galo} alt="Galo's pix" className="mx-auto sm:float-right w-20 rounded-md"/>
      </div>
      <div className="sm:w-1/2 w-9/12 mt-auto mb-auto">
        <h2 className="text-black dark:text-white text-lg">Galo Berlyn Garlejo</h2>
        <p className="dark:text-grey text-sm">Indie App Developer</p>
      </div>
    </div>
  )
}