import { AppContainer } from './AppContainer';
import { About } from './components/about';
import { Header } from './components/header';
import { Name } from './components/name';
import { Links } from './components/links';
import { Experience } from './components/experience';
import { Contact } from './components/contact';
import { useTheme } from './hooks/useTheme';
import { Projects } from './components/projects';

function App() {

  const { isDarkMode, handleToggle } = useTheme();

  return (
    <AppContainer 
      about={<About />}
      header={<Header isDarkMode={isDarkMode} toggleDarkMode={handleToggle}/>}
      name={<Name />}
      links={<Links />}
      experience={<Experience />}
      contact={<Contact />}
      projects={<Projects />}
    />
  );
}

export default App;
