type AppContainerTypes = {
  about: React.ReactNode
  name: React.ReactNode
  links: React.ReactNode
  experience: React.ReactNode
  contact: React.ReactNode
  header: React.ReactNode
  projects: React.ReactNode
}

export const AppContainer: React.FC<AppContainerTypes> = ({
  about,
  name,
  links,
  experience,
  contact,
  header,
  projects,
}) => {

  const sectionClassName = `
  xl:mx-96 2xl:mx-96 lg:mx-56 
  md:mx-44 sm:mx-1 
  px-10 lg:px-28  2xl:px-64
  pt-10 pb-10
  `;

  return (
    <div>
      <div>
        {header}
      </div>
      <div className="lg:mx-56 md:mx-56 p-5 md:p-10 sm:mx-1">
        {name}
      </div>
      <div className={sectionClassName}>
        {about}
      </div>
      <div className={sectionClassName}>
        {experience}
      </div>
      <div className={sectionClassName}>
        {projects}
      </div>
      <div className={sectionClassName}>
        {contact}
      </div>
      <div className={sectionClassName}>
        {links}
      </div>
    </div>
  )
}