export const Links = () => {
  
  const data = [
    {
      title: 'Github',
      link: 'https://github.com/galoberlyn',
    },
    {
      title: 'Instagram',
      link: 'https://instagram.com/galoberlyn',
    },
    {
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/in/galo-berlyn-g-083943141/',
    }, 
  ]

  return (
    <div className="mx-auto">
      <div className='w-full'>
        <h2 className="text-black dark:text-white text-lg">Links</h2>
      </div>
      <div className="">
        { data.map(d => (
          <div key={d.link} className="flex">
            <div className="w-5/12">
              <h3 className="text-black dark:text-white text-sm">
                {d.title}
              </h3>
            </div>
            <div className="w-7/12 mb-5">
              <p className="text-black dark:text-grey text-sm mb-3">
                <a href={d.link} rel="noreferrer" className="text-blue" target="_blank">{d.link}</a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}